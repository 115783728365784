import { ServerResponse } from 'http';
import Debug from 'debug';

import { formatUrl, isScopeableRoute, parseUrl } from '../utils';
import getPublicEndpoint from '../../getPublicEndpoint';
import { httpRedirect } from '../httpRedirect';
import { isInPathScope } from '../AppRouter';
import { State } from '../../store/types';

import {
  selectAccountIdSessionScope,
  selectPathSessionScope,
} from '../../store/session/selectors';

import {
  LANGUAGE_QUERY_PARAM,
  COUNTRY_QUERY_PARAM,
  DEVICE_TYPE_QUERY_PARAM,
  BYPASS_EDGE_CACHE_PARAM,
  BYPASS_COUNTRY_BLOCKING,
  SCOPE_QUERY_PARAM,
  SONGWHIP_NO_CACHE_QUERY_PARAM,
} from '~/config';

const debug = Debug('songwhip/router/redirectIfNeeded');

/**
 * Handles any needed server/http redirects when:
 *
 * - A. The expected pagePath doesn't match the current router.asPath
 * - B. There is an active scope and the current page is not 'scopeable'
 * - C. There is an active 'account:' scope and current page is not owned by said account
 * - D. There is an active 'path:' scope and the resolved path does not start with said path
 *
 * TODO: check this redirects with the correct baseUrl when behind edgeWorker.
 */
export const serverRedirectIfNeeded = (params: {
  res: ServerResponse;
  state: State;
  expectedPagePath?: string;
  currentAsPath: string;
  currentRoutePathname: string;
  pageOwnedByAccountIds?: number[] | undefined;
}) => {
  const {
    res,
    state,
    expectedPagePath,
    currentAsPath,
    currentRoutePathname,
    pageOwnedByAccountIds,
  } = params;

  // it's completely possible that a redirect response has
  // already been sent further up the call stack node/vercel
  // can blow up if you try to write a response twice
  const responseAlreadySent = res.headersSent || res.finished;
  if (responseAlreadySent) return;

  const activeAccountIdScope = selectAccountIdSessionScope(state);
  const activePathScope = selectPathSessionScope(state);
  const hasActiveScope = !!(activeAccountIdScope || activePathScope);
  const currentAsUrl = parseUrl(currentAsPath);
  const baseUrl = getPublicEndpoint();

  // if a scope is in place but the current page path isn't scopeable
  // (eg. '/faq') then we redirect
  if (hasActiveScope && !isScopeableRoute(currentRoutePathname)) {
    debug('redirecting: is not scopeable page');

    httpRedirect({
      res,
      url: toRedirectUrl({
        baseUrl,
        path: currentAsPath,
      }),
    });

    return;
  }

  // if there's an active Account scope for this session and the given page
  // is NOT owned by said Account then we gotta bust out that scope
  // (only run check if the `pageOwnedByAccountIds` param was provided)
  if (
    'pageOwnedByAccountIds' in params && // param provided
    activeAccountIdScope && // app is under accountId scope
    // page either NOT owned or scoped accountId NOT in list of account owners
    !pageOwnedByAccountIds?.includes(activeAccountIdScope)
  ) {
    debug(
      'redirecting: page-accounts !== scoped-account(%s)',
      activeAccountIdScope,
      pageOwnedByAccountIds
    );

    httpRedirect({
      res,
      url: toRedirectUrl({
        baseUrl,
        path: currentAsPath,
      }),
    });

    return;
  }

  if (!isInPathScope(activePathScope, currentAsUrl.pathname)) {
    httpRedirect({
      res,
      url: toRedirectUrl({
        baseUrl,
        path: currentAsPath,
      }),
    });

    return;
  }

  // If the resolved item's pagePath is different than the
  // path being rendered then we redirect to the correct path.
  // This happens when hitting a 'secondary' Path which resolves
  // to an Artist/Album/Track than has a different 'primary' path.
  // The primary path is canonical, so we should always use that.
  if (expectedPagePath && expectedPagePath !== currentAsUrl.pathname) {
    httpRedirect({
      res,
      url: toRedirectUrl({
        path: expectedPagePath,
      }),
    });

    return;
  }
};

const toRedirectUrl = ({
  baseUrl,
  path,
}: {
  baseUrl?: string;
  path: string;
}) => {
  const url = parseUrl(path);

  // Remove the scope param and redirect to the baseUrl. When proxied
  // behind sng.to, the baseUrl should remain songwhip.com.
  // ensure no edge internal params are leaked in the new redirect url
  [
    COUNTRY_QUERY_PARAM,
    LANGUAGE_QUERY_PARAM,
    DEVICE_TYPE_QUERY_PARAM,
    SONGWHIP_NO_CACHE_QUERY_PARAM,
    BYPASS_EDGE_CACHE_PARAM,
    BYPASS_COUNTRY_BLOCKING,

    // remove 'scope' param so we don't end up in an endless
    // loop attempting to break out of an invalid scope
    SCOPE_QUERY_PARAM,
  ].forEach((key) => {
    delete url.query[key];
  });

  const asPath = formatUrl(url);

  // when no baseUrl: just return the origin relative path (eg. '/foo/bar?a=1')
  // this means that redirects can bubble up to the custom domain and preserve
  // scoping (eg. cdbaby.sng.to) without breaking out to the app's baseUrl.
  return baseUrl ? new URL(asPath, baseUrl).toString() : asPath;
};
